import React, { useEffect, useState } from 'react';
import { Form, Input, Button,message } from 'antd';
import { useHistory } from 'react-router-dom';
import { registerUser } from '../services/UserService';

const RegistrationPage = () => {
  useEffect(() => {
    document.title = '嘉有儿女 - 注册';
  }, []);

  const history = useHistory();

  const onFinish = async (values) => {
    try {
      const response = await registerUser(values);
      
      if (response.status === 201) {
        // 处理注册成功的情况，例如跳转到登录页面
        console.log('注册成功');
        message.success('注册成功');
      } else {
        // 处理注册失败的情况
        console.log('注册失败');
        message.error('注册失败');
      }
    } catch (error) {
      // 处理请求失败的情况
      console.error('注册请求失败:', error);
      message.error('请求失败，请重试'); // 或者使用更具体的错误信息
    }
  };
  

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
      }}
    >
      <div style={{ width: '100%', maxWidth: '400px' }}>
        <h1 style={{ textAlign: 'center' }}>用户注册</h1>
        <Form name="register" onFinish={onFinish}>
          <Form.Item
            label="用户名"
            name="username"
            rules={[
              { required: true, message: '请输入用户名' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[
              { required: true, message: '请输入密码' },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              { required: true, message: '请输入邮箱' },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ display: 'block', margin: '0 auto' }}>
              注册
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default RegistrationPage;
