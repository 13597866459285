import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { createUserDetail } from '../services/UserDetailService';
import GetCookie from '../utils/GetCookie';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 8 },
};

const UserDetail = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    document.title = '嘉有儿女 - 创建用户详情';
  }, []);

  const onFinish = (values) => {

    // 获取 token
      const token = GetCookie.getCookie('token');

    // 如果没有 token，跳转到登录页
    if (!token) {
      history.push('/login');
      return;
    }

    // 如果有 token，添加到请求头中
    const headers = {
      Authorization: token,
    };

    console.log('headers:', headers)

    // 使用服务来创建用户详情，带上 headers
    createUserDetail('https://api.delistudio.top/user-detail/create-user-detail', values, headers)
      .then((data) => {
        message.success('创建成功')
        console.log('User detail created successfully:', data);
      })
      .catch((error) => {
        console.error('Failed to create user detail:', error);
        if (error.response && error.response.status === 401) {
          // 处理 401 错误，例如执行重定向到登录页面
          console.log('Unauthorized access. Redirecting to login page.');
          window.location.href = '/login'; // 重定向到登录页面
        }
        if (error.response && error.response.status === 400) {
          message.error('创建失败!')
        }
      });

  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>创建用户详情</h1>
      <Form {...layout} form={form} name="userDetailForm" onFinish={onFinish}>
        <Form.Item
          label="用户名"
          name="username"
          rules={[{ required: true, message: 'Please input the username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="真实姓名"
          name="realName"
          rules={[{ required: true, message: 'Please input the real name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="电话号码"
          name="phone"
          rules={[{ required: true, message: 'Please input the phone!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="调理项目"
          name="projects"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="邮箱"
          name="email"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="会员卡余额"
          name="balance"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="总次数"
          name="count"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="备注"
          name="description"
        >
          <Input />
        </Form.Item>

        {/* 添加更多字段的表单项 */}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UserDetail;
