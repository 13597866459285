import {
  EditOutlined,
  HomeFilled,
  LoginOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, message } from 'antd';
import { useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import '../App.css';
import GetCookie from '../utils/GetCookie';
import UserDetail from './CreateUserDetail'; // 这里导入你的页面组件
import DefaultPage from './DefaultContent';
import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import UpdateUserDetail from './UpdateUserDetail';
import UserDetailsList from './UserDetailsList'; // 同上

const { Header, Content, Footer } = Layout;

const HomePage = () => {
  const location = useLocation();
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const setDocumentTitle = (title) => {
    document.title = title;
  };

  useEffect(() => {
    // 从 localStorage 中读取登录状态
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    }

    if (location.state && location.state.isLoggedIn) {
      setIsLoggedIn(true);
    }
  }, [location]);

  const handleLogout = () => {
    // 实际的注销逻辑应该在这里处理
    console.log('注销');
    setIsLoggedIn(false);
    GetCookie.clearAllCookies();
    localStorage.removeItem('isLoggedIn');
    message.success('注销成功');
    history.push('/login');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
          <Menu.Item key="1" icon={<HomeFilled />} onClick={() => setDocumentTitle('首页 - 欢迎来到嘉有儿女')}>
            <Link to="/home">主页</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<LoginOutlined />}>
            <Link to="/login">登录</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<UserAddOutlined />}>
            <Link to="/register">注册</Link>
          </Menu.Item>
          {isLoggedIn && (
            <>
              <Menu.Item key="4" icon={<UserOutlined />}>
                <Link to="/create-user-detail">创建用户详情</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<TeamOutlined />}>
                <Link to="/user-detail-list">用户详情列表</Link>
              </Menu.Item>
              <Menu.Item key="7" icon={<EditOutlined />}>
                <Link to="/update-user-detail/null">更新用户详情</Link>
              </Menu.Item>
              <Menu.Item key="6" icon={<LogoutOutlined />} onClick={handleLogout}>
                注销
              </Menu.Item>
            </>
          )}
        </Menu>
      </Header>
      <Layout className="site-layout" style={{ marginTop: 64 }}>
        <Content style={{ margin: '0 16px' }}>
          <Switch>
            <Route path="/create-user-detail" component={UserDetail} />
            <Route path="/user-detail-list" component={UserDetailsList} />
            <Route path="/login" component={LoginPage} />
            <Route path="/register" component={RegistrationPage} />
            <Route path="/update-user-detail/:username" component={UpdateUserDetail} />{/* 必须要":" */}
            <Route path="/home" component={DefaultPage} />
            {/* 添加重定向路由,使其用户在打开根目录时，直接展示欢迎页面 */}
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            {/* ...其他路由... */}
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', backgroundColor: '#4CAF50', color: '#fff' }}>
          嘉有儿女 ©{new Date().getFullYear()} 由DELISTUDIO.TOP支持
        </Footer>
      </Layout>
    </Layout>
  );
};

export default HomePage;