import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

const DefaultPage = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100%', // 让页面铺满整个视窗高度
        }}>
            <Title style={{ color: '#4CAF50' }}>欢迎来到嘉有儿女</Title>
            <Paragraph style={{ color: '#4CAF50', fontSize: '1.5em' }}>
                专业小儿推拿服务，呵护宝宝健康成长
            </Paragraph>
            
            {/* 视频部分 */}
            <div style={{ marginTop: '20px', maxWidth: '800px' }}>
                <video controls width="100%">
                    <source src="https://file.delistudio.top/api/public/dl/-wRpA3rO?inline=true" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
};

export default DefaultPage;