import { Button, Spin, Table, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAllUserDetails } from "../services/UserDetailService"; // Import service
import GetCookie from "../utils/GetCookie";

const UserDetailsList = () => {
  useEffect(() => {
    document.title = "嘉有儿女 - 用户详情列表";
  }, []);

  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Declare currentPage state here
  const [total, setTotal] = useState(0); // 添加这行代码来定义 total 状态
  const [pageSize, setPageSize] = useState(10); // 默认每页显示10条数据
  const history = useHistory();

  // Define fetchPageData function here
  const fetchPageData = (page) => {
    setLoading(true);
    const token = GetCookie.getCookie("token");
    if (!token) {
      history.push("/login");
      return;
    }

    // If token is present, add it to the request headers
    const headers = {
      Authorization: token, // Ensure you're using the correct auth header
    };

    getAllUserDetails(headers, page, pageSize) // Assuming the service accepts page and pageSize parameters
      .then((data) => {
        setUserDetails(data.list || data); // Set userDetails to data.list or data if list is not present
        setCurrentPage(data.pageNum); // Set the current page
        setTotal(data.total); // 更新总记录数
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // 当用户改变每页显示条数时调用的函数
  const handlePageSizeChange = (current, size) => {
    setCurrentPage(1); // 回到第一页
    setPageSize(size); // 异步更新 pageSize 状态
    console.log("handlePageSizeChange=>pageSize:", pageSize);
  };

  // 添加一个 useEffect 专门用于处理 pageSize 变化
  useEffect(() => {
    fetchPageData(currentPage, pageSize);
  }, [currentPage, pageSize]); // 依赖项数组中包含 currentPage 和 pageSize

  const columns = [
    // ... Define columns here ...
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "用户名", dataIndex: "username", key: "username" },
    { title: "真实姓名", dataIndex: "realName", key: "realName" },
    { title: "电话号码", dataIndex: "phone", key: "phone" },
    { title: "调理项目", dataIndex: "projects", key: "projects" },
    { title: "邮箱", dataIndex: "email", key: "email" },
    { title: "会员卡余额", dataIndex: "balance", key: "balance" },
    { title: "总次数", dataIndex: "count", key: "count" },
    { title: "备注", dataIndex: "description", key: "description" },
    {
      title: "操作",
      key: "action",
      render: (text, record) => (
        <span>
          <Button
            type="primary"
            onClick={() =>
              history.push(`/update-user-detail/${record.username}`)
            }
          >
            修改
          </Button>
          <Button type="primary" danger onClick={() => handleDelete(record)}>
            删除
          </Button>
        </span>
      ),
    },
  ];

  const handleDelete = async (record) => {
    // 编写删除记录的逻辑
    console.log("点击了删除按钮,记录ID:", record.username);
    const username = record.username;
    if (username) {
      console.log("pi username:", username);
      const token = GetCookie.getCookie("token");
      if (!token) {
        history.push("/login");
        return;
      }
      try {
        const response = await axios.delete(
          `https://api.delistudio.top/user-detail/${username}`,
          {
            headers: {
              Authorization: token, 
            },
          }
        );
        message.success("删除用户详情成功");
        console.log("response:", response);
        // 处理删除成功的逻辑，例如刷新数据等
        fetchPageData(currentPage, pageSize);
      } catch (error) {
        console.error("删除用户详情失败", error);
        message.error("删除用户详情失败");
      }
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h1>用户详情列表</h1>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={userDetails}
          columns={columns}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            onChange: fetchPageData,
            onShowSizeChange: handlePageSizeChange, // 添加这个回调来处理每页显示条数的变化
            showSizeChanger: true, // 允许改变每页显示的数量
          }}
        />
      )}
    </div>
  );
};

export default UserDetailsList;
