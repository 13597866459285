import axios from 'axios';

// 定义后端API的URL
const API_BASE_URL = 'https://api.delistudio.top/user';

// 使用Axios来发起请求
const instance = axios.create({ baseURL: API_BASE_URL });

// 用户登录
export const login = async (values) => {
    try {
        const response = await instance.post('/login', values);
        return response.data;
    } catch (error) {
        console.error('Login error:', error);
        throw error;
    }
};

//用户注册
export  const registerUser=async (data)=>{
    try {
        const response = await instance.post('/register', data);
        console.log('user service register',response)
        return response;
    } catch (error) {
        console.error('Register error:', error);
        throw error;
    }
}
